import React from 'react';
import { Col, Divider, Layout, Row } from 'antd';
import ItemsTable from './ItemsTable';
import DetailsSection from './DetailsSection';
import { useIntl } from 'react-intl';
import messages from './messages';
import ReturnsTable from './ReturnsTable';

const ReturnForm = ({ order, setDataProcessed, dataProcessed }) => {
  const intl = useIntl();

  return (
    <Layout
      style={{
        padding: 15,
        paddingBottom: 48,
        height: 'calc(100vh - 128px)',
        overflow: 'auto',
      }}
    >
      <Row gutter={{ xs: 24, sm: 16, md: 16, lg: 8, xxl: 8 }}>
        <DetailsSection
          data={order}
          setDataProcessed={setDataProcessed}
          dataProcessed={dataProcessed}
        />
        <Col span={24}>
          <Divider orientation="middle">
            {intl.formatMessage(messages.returnFormItems)}
          </Divider>
          <ItemsTable
            data={order}
            loading={order?.loading}
            dataProcessed={dataProcessed}
            setDataProcessed={setDataProcessed}
          />
          <br />
          <br />
          <Divider orientation="middle">
            {intl.formatMessage(messages.returnFormReturns)}
          </Divider>
          <ReturnsTable data={order?.returns} loading={order?.loading} />
        </Col>
      </Row>
    </Layout>
  );
};

export default ReturnForm;
