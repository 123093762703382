import React from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import messages from './messages';
import { useIntl } from 'react-intl';

const Imprint = () => {
  const intl = useIntl();

  return (
    <Layout style={{ height: 'calc(100vh - 130px)', overflowY: 'auto' }}>
      <Row align="top">
        <Col style={{ padding: 20 }}>
          <Typography.Title style={{ color: 'blue' }}>
            {intl.formatMessage(messages.imprintTitle)}
          </Typography.Title>

          <Typography.Paragraph strong>
            {intl.formatMessage(messages.imprintHeader)}
          </Typography.Paragraph>
          <Typography.Paragraph style={{ whiteSpace: 'pre-wrap' }}>
            {intl.formatMessage(messages.imprintAddress)}
          </Typography.Paragraph>

          <Typography.Paragraph strong>
            {intl.formatMessage(messages.imprintDirectorHeader)}
          </Typography.Paragraph>
          <Typography.Paragraph style={{ whiteSpace: 'pre-wrap' }}>
            {intl.formatMessage(messages.imprintDirectorText)}
          </Typography.Paragraph>

          <Typography.Paragraph strong>
            {intl.formatMessage(messages.imprintRegistryHeader)}
          </Typography.Paragraph>
          <Typography.Paragraph style={{ whiteSpace: 'pre-wrap' }}>
            {intl.formatMessage(messages.imprintRegistryText)}
          </Typography.Paragraph>

          <Typography.Paragraph strong>
            {intl.formatMessage(messages.imprintVatHeader)}
          </Typography.Paragraph>
          <Typography.Paragraph style={{ whiteSpace: 'pre-wrap' }}>
            {intl.formatMessage(messages.imprintVatText)}
          </Typography.Paragraph>
        </Col>
      </Row>
    </Layout>
  );
};
export default Imprint;
