import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Table, Input, Select, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import axios from 'axios';
import messages from './messages';
import {
  STATUS_TYPE_ARRANGED,
  STATUS_TYPE_CANCELED,
  STATUS_TYPE_DELIVERED,
  STATUS_TYPE_FOLLOWUP,
  STATUS_TYPE_NOTDELIVERED,
  STATUS_TYPE_PREPARATION,
  STATUS_TYPE_RECEIVED,
  STATUS_TYPE_SHIPPED,
  RETURN_REASON_NONE,
  RETURN_REASON_LATEDELIVERY,
  RETURN_REASON_DONTLIKEANYMORE,
  RETURN_REASON_MISSINGPARTSORACCESSORIESMISSING,
  RETURN_REASON_ITEMBROKENORDAMAGED,
  RETURN_REASON_MISPICKWRONGITEMDELIVERED,
  RETURN_REASON_FOUNDCHEAPERPRICE,
  RETURN_REASON_SHIPPINGPACKAGINGDAMAGED,
  RETURN_REASON_WRONGDESCRIPTION,
  RETURN_REASON_ITEMTOOBIGORSMALL,
} from './constants';

const getStatusTypes = (intl) => [
  {
    label: intl.formatMessage(messages.statusReceived),
    value: STATUS_TYPE_RECEIVED,
  },
  {
    label: intl.formatMessage(messages.statusPreparation),
    value: STATUS_TYPE_PREPARATION,
  },
  {
    label: intl.formatMessage(messages.statusShipped),
    value: STATUS_TYPE_SHIPPED,
  },
  {
    label: intl.formatMessage(messages.statusDelivered),
    value: STATUS_TYPE_DELIVERED,
  },
  {
    label: intl.formatMessage(messages.statusCanceled),
    value: STATUS_TYPE_CANCELED,
  },
  {
    label: intl.formatMessage(messages.statusNotDelivered),
    value: STATUS_TYPE_NOTDELIVERED,
  },
  {
    label: intl.formatMessage(messages.statusFollowUp),
    value: STATUS_TYPE_FOLLOWUP,
  },
  {
    label: intl.formatMessage(messages.statusArranged),
    value: STATUS_TYPE_ARRANGED,
  },
];

const getReturnReasons = (intl) => [
  {
    label: intl.formatMessage(messages.returnReason_None),
    value: RETURN_REASON_NONE,
  },
  {
    label: intl.formatMessage(messages.returnReason_LateDelivery),
    value: RETURN_REASON_LATEDELIVERY,
  },
  {
    label: intl.formatMessage(messages.returnReason_DontLikeAnymore),
    value: RETURN_REASON_DONTLIKEANYMORE,
  },
  {
    label: intl.formatMessage(messages.returnReason_MissingPartsOrAccessories),
    value: RETURN_REASON_MISSINGPARTSORACCESSORIESMISSING,
  },
  {
    label: intl.formatMessage(messages.returnReason_ItemBrokenOrDamaged),
    value: RETURN_REASON_ITEMBROKENORDAMAGED,
  },
  {
    label: intl.formatMessage(messages.returnReason_WrongItemDelivered),
    value: RETURN_REASON_MISPICKWRONGITEMDELIVERED,
  },
  {
    label: intl.formatMessage(messages.returnReason_FoundCheaperPrice),
    value: RETURN_REASON_FOUNDCHEAPERPRICE,
  },
  {
    label: intl.formatMessage(messages.returnReason_ShippingPackageDamaged),
    value: RETURN_REASON_SHIPPINGPACKAGINGDAMAGED,
  },
  {
    label: intl.formatMessage(messages.returnReason_WrongDescription),
    value: RETURN_REASON_WRONGDESCRIPTION,
  },
  {
    label: intl.formatMessage(messages.returnReason_ItemTooBigOrSmall),
    value: RETURN_REASON_ITEMTOOBIGORSMALL,
  },
];

const getColumns = (intl) => [
  {
    title: intl.formatMessage(messages.positionNumber),
    dataIndex: 'positionNumber',
  },
  {
    title: intl.formatMessage(messages.itemNumber),
    dataIndex: 'itemNumber',
  },
  {
    title: intl.formatMessage(messages.itemName),
    dataIndex: 'description',
  },
  {
    title: intl.formatMessage(messages.quantity),
    dataIndex: 'totalQuantity',
  },
  {
    title: intl.formatMessage(messages.status),
    dataIndex: 'positionStatus',
    render: (text) =>
      getStatusTypes(intl).find((element) => element.value === text)?.label,
  },
];

const getEditColumns = (intl, positions, handleInputChange) => [
  {
    title: intl.formatMessage(messages.positionNumber),
    dataIndex: 'positionNumber',
    key: 'positionNumber',
  },
  {
    title: intl.formatMessage(messages.itemNumber),
    dataIndex: 'itemNumber',
  },
  {
    title: intl.formatMessage(messages.itemName),
    dataIndex: 'description',
  },
  {
    title: intl.formatMessage(messages.quantity),
    dataIndex: 'askedQuantity',
    key: 'askedQuantity',
    render: (text, record, index) => {
      const isNotDelivered =
        positions[index].positionStatus !== STATUS_TYPE_DELIVERED;
      return (
        <Input
          type="number"
          maxLength={4}
          value={
            isNotDelivered
              ? positions[index].totalQuantity
              : record.askedQuantity
          }
          disabled={isNotDelivered}
          onChange={(e) =>
            handleInputChange(
              e.target.value,
              positions[index].positionNumber,
              'askedQuantity',
            )
          }
        />
      );
    },
  },
  {
    title: intl.formatMessage(messages.returnReason),
    dataIndex: 'returnReason',
    key: 'returnReason',
    render: (text, record, index) => {
      const isNotDelivered =
        positions[index].positionStatus !== STATUS_TYPE_DELIVERED;
      return (
        <Select
          value={record.returnReason}
          style={{ width: '100%' }}
          options={getReturnReasons(intl)}
          disabled={isNotDelivered}
          onChange={(value) =>
            handleInputChange(
              value,
              positions[index].positionNumber,
              'returnReason',
            )
          }
        />
      );
    },
  },
  {
    title: intl.formatMessage(messages.status),
    dataIndex: 'positionStatus',
    render: (text) =>
      getStatusTypes(intl).find((element) => element.value === text)?.label,
  },
];

const ItemsTable = ({ data, loading, dataProcessed, setDataProcessed }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { orderNumber, customerPostcode, positions } = data;
  const [retPositions, setRetPositions] = useState(positions);

  const handleInputChange = (value, recordKey, column) => {
    setRetPositions((prev) =>
      prev.map((row) =>
        row?.positionNumber === recordKey ? { ...row, [column]: value } : row,
      ),
    );
  };

  const saveData = async () => {
    try {
      const validRetPositions = retPositions.filter(
        (retPosition) =>
          retPosition.positionStatus === STATUS_TYPE_DELIVERED &&
          retPosition.askedQuantity > 0 &&
          retPosition.returnReason,
      );

      const returnObject = {
        orderNumber,
        returnPositions: validRetPositions,
      };

      await axios.post(
        `/api/home-delivery/returns/create-label-by-customer`,
        returnObject,
      );

      message.success(intl.formatMessage(messages.apiCreateReturnSuccess));

      const refreshObject = {
        orderNumber,
        Postcode: customerPostcode,
      };

      var response = await axios.post(
        `/api/home-delivery/orders/is-returnable-by-user-request`,
        refreshObject,
      );

      setDataProcessed(false);
      navigate('/return', {
        state: { order: response.data },
      });
    } catch (error) {
      const { Errors } = JSON.parse(error.request.response);
      if (Errors) {
        Errors.map((e) => message.error(e));
      } else {
        message.error(error.message);
      }
    }
  };

  return (
    <>
      <Table
        columns={
          !dataProcessed
            ? getColumns(intl)
            : getEditColumns(intl, positions, handleInputChange)
        }
        dataSource={positions}
        pagination={false}
        loading={loading}
        bordered
        size="small"
        rowKey="positionNumber"
      />
      {dataProcessed && (
        <Button
          type="primary"
          style={{ background: 'green', marginTop: 15 }}
          onClick={saveData}
        >
          Save
        </Button>
      )}
    </>
  );
};

ItemsTable.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  dataProcessed: PropTypes.bool,
  setDataProcessed: PropTypes.func,
};

export default ItemsTable;
