import React, { useEffect, useState } from 'react';
import en from '../../translations/en.json';
import de from '../../translations/de.json';
import { IntlProvider } from 'react-intl';

const appMessages = { en: en, de: de };
const LanguageContext = React.createContext({ locale: 'en' });
const LanguageProvider = ({ children }) => {
  const [locale, setLocale] = useState(getNavigatorLanguage());
  const [messages, setMessages] = useState(appMessages[getNavigatorLanguage()]);

  useEffect(() => {
    if (locale === 'en') {
      setMessages(appMessages['en']);
    } else if (locale === 'de') {
      setMessages(appMessages['de']);
    } else {
      setMessages(appMessages['en']);
    }
  }, [locale]);

  return (
    <LanguageContext.Provider value={{ locale, setLocale }}>
      <IntlProvider
        key={locale}
        locale={locale}
        messages={messages}
        defaultLocale={locale}
      >
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

const getNavigatorLanguage = () => {
  const lang =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.userLanguage ||
        navigator.language ||
        navigator.browserLanguage ||
        'en';

  return lang.split('-')[0];
};

export default LanguageProvider;
