import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import messages from './messages';
import { useIntl } from 'react-intl';
import axios from 'axios';
import { useContext } from 'react';
import { AuthContext } from '../context/Auth';

const useLogin = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { setLoggedIn } = useContext(AuthContext);

  const login = async (values) => {
    try {
      var response = await axios.post(
        `/api/home-delivery/orders/is-returnable-by-user-request`,
        values,
      );
      message.success(intl.formatMessage(messages.apiLoginSuccess));
      setLoggedIn(true);
      navigate('/return', {
        state: { order: response.data },
      });
    } catch (error) {
      setLoggedIn(false);
      const { Errors } = JSON.parse(error.request.response);
      if (Errors) {
        Errors.map((e) => message.error(e));
      } else {
        message.error(error.message);
      }
    }
  };

  return { login };
};

export default useLogin;
