import React from "react";
import { Col, Layout, Row, Typography } from "antd";
import messages from "./messages";
import { useIntl } from "react-intl";

const Privacy = () => {
  const intl = useIntl();

  return (
    <Layout>
      <Row align="top">
        <Col style={{ padding: 20 }}>
          <Typography.Title style={{ color: "blue" }}>
            {intl.formatMessage(messages.privacyTitle)}
          </Typography.Title>

          <Typography.Paragraph strong>
            {intl.formatMessage(messages.privacy1Title)}
          </Typography.Paragraph>
          <Typography.Paragraph style={{ whiteSpace: "pre-wrap" }}>
            {intl.formatMessage(messages.privacy1Text)}
          </Typography.Paragraph>

          <Typography.Paragraph strong>
            {intl.formatMessage(messages.privacy2Title)}
          </Typography.Paragraph>
          <Typography.Paragraph style={{ whiteSpace: "pre-wrap" }}>
            {intl.formatMessage(messages.privacy2Text)}
          </Typography.Paragraph>

          <Typography.Paragraph strong>
            {intl.formatMessage(messages.privacy3Title)}
          </Typography.Paragraph>
          <Typography.Paragraph style={{ whiteSpace: "pre-wrap" }}>
            {intl.formatMessage(messages.privacy3Text)}
          </Typography.Paragraph>

          <Typography.Paragraph strong>
            {intl.formatMessage(messages.privacy4Title)}
          </Typography.Paragraph>
          <Typography.Paragraph style={{ whiteSpace: "pre-wrap" }}>
            {intl.formatMessage(messages.privacy4Text)}
          </Typography.Paragraph>

          <Typography.Paragraph strong>
            {intl.formatMessage(messages.privacyContactTitle)}
          </Typography.Paragraph>
          <Typography.Paragraph style={{ whiteSpace: "pre-wrap" }}>
            {intl.formatMessage(messages.privacyContactText)}
          </Typography.Paragraph>
        </Col>
      </Row>
    </Layout>
  );
};

export default Privacy;
