import React, { useCallback } from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import messages from "./messages";

const NotFound = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate("/login"), [navigate]);

  return (
    <Result
      status="404"
      title="404"
      subTitle={intl.formatMessage(messages.notFoundMessage)}
      extra={<Button type="primary" onClick={goBack}>{intl.formatMessage(messages.notFoundBack)}</Button>}
    />
  );
};

export default NotFound;
