import React from "react";
import Imprint from "./Imprint";
import Privacy from "./Privacy";

const PrivacyPolicyView = ({ imprint }) => {
  return (
    imprint ? <Imprint /> : <Privacy />
  );
};

export default PrivacyPolicyView;
