import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Divider, Space, Typography } from 'antd';
import { useIntl } from 'react-intl';
import messages from './messages';

const DetailsSection = ({ data, setDataProcessed, dataProcessed }) => {
  const intl = useIntl();
  return (
    <>
      <Col style={{ paddingRight: 50 }}>
        <Divider orientation="left" orientationMargin={5}>
          {intl.formatMessage(messages.returnFormOrder)}
        </Divider>
        <Space style={{ padding: 8 }}>
          <Typography.Text>
            {intl.formatMessage(messages.returnFormOrderNumber, {
              number: data?.orderNumber,
            })}
          </Typography.Text>
        </Space>
      </Col>
      <Col>
        <Divider orientation="left" orientationMargin={5}>
          {intl.formatMessage(messages.returnFormCustomer)}
        </Divider>
        <Space direction="vertical" style={{ padding: 8 }}>
          <Typography.Text>
            {intl.formatMessage(messages.returnFormCustomerName, {
              name: data?.customerFirstName + ' ' + data?.customerLastName,
            })}
          </Typography.Text>
          <Typography.Text>
            {intl.formatMessage(messages.returnFormCustomerExtra, {
              extra: data?.customerExtra,
            })}
          </Typography.Text>
          <Typography.Text>
            {intl.formatMessage(messages.returnFormCustomerStreet, {
              street:
                data?.customerStreetName + ' ' + data?.customerStreetNumber,
            })}
          </Typography.Text>
          <Typography.Text>
            {intl.formatMessage(messages.returnFormCustomerZipCity, {
              city: data?.customerPostcode + ' ' + data?.customerCity,
            })}
          </Typography.Text>
          <Typography.Text>
            {intl.formatMessage(messages.returnFormCustomerCountry, {
              country: data?.customerCountry,
            })}
          </Typography.Text>
          <Space style={{ marginTop: 10 }}>
            <Typography.Text>
              {intl.formatMessage(messages.returnFormCustomerEmail, {
                email: data?.customerEmail,
              })}
            </Typography.Text>
          </Space>
        </Space>
      </Col>
      <Col>
        <Divider orientation="left" orientationMargin={5}>
          Return
        </Divider>
        <Space style={{ padding: 8 }}>
          <Button
            type={dataProcessed ? '' : 'primary'}
            style={dataProcessed ? { background: 'lightgrey'} : ''}
            onClick={() => {
              setDataProcessed(!dataProcessed);
            }}
          >
            {dataProcessed
              ? intl.formatMessage(messages.returnFormCancel)
              : intl.formatMessage(messages.returnFormCreateReturn)}
          </Button>
        </Space>
      </Col>
    </>
  );
};

DetailsSection.propTypes = {
  data: PropTypes.object,
  setDataProcessed: PropTypes.func,
  dataProcessed: PropTypes.bool,
};

export default DetailsSection;
