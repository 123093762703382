import React, { useCallback, useContext } from 'react';
import { AuthContext } from '../../context/Auth';
import { Button, Layout, Result } from 'antd';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import messages from './messages';
import ReturnForm from '../../components/ReturnForm';

const Return = () => {
  const intl = useIntl();
  const { loggedIn, dataProcessed, setDataProcessed } = useContext(AuthContext);
  const navigate = useNavigate();
  const { state } = useLocation();

  const goToLogin = useCallback(() => navigate('/login'), [navigate]);

  if (!loggedIn) {
    return (
      <Layout style={{ height: 'calc(100vh - 128px)' }}>
        <Result
          status="403"
          title="403"
          style={{ overflow: 'auto' }}
          subTitle={intl.formatMessage(messages.returnGoToLogin)}
          extra={
            <Button type="primary" onClick={goToLogin}>
              {intl.formatMessage(messages.returnBack)}
            </Button>
          }
        />
      </Layout>
    );
  }

  return (
    <ReturnForm
      order={state?.order}
      dataProcessed={dataProcessed}
      setDataProcessed={setDataProcessed}
    />
  );
};

export default Return;
