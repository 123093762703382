import React from 'react';
import PrivacyPolicyView from '../../components/PrivacyPolicyView';
import { Layout } from 'antd';

const PrivacyPolicy = ({ imprint }) => {
  return (
    <Layout style={{ height: 'calc(100vh - 128px)', overflow: 'auto' }}>
      <PrivacyPolicyView imprint={imprint} />
    </Layout>
  );
};

export default PrivacyPolicy;
