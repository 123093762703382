import React from 'react';
import { Footer as AntdFooter } from 'antd/lib/layout/layout';
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import messages from './messages';

const Footer = () => {
  const intl = useIntl();
  return (
    <AntdFooter
      style={{
        backgroundColor: '#001529',
        paddingLeft: 40,
        paddingRight: 40,
        position: 'fixed',
        zIndex: 1,
        width: '100%',
        bottom: 0,
        flex: 1,
      }}
    >
      <Row
        justify="space-around"
        gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
        align="middle"
      >
        <Col span={12} style={{ display: 'flex', justifyContent: 'end' }}>
          <Link to="/privacy-policy">
            {intl.formatMessage(messages.privacyPolicy)}
          </Link>
        </Col>
        <Col span={12} style={{ display: 'flex', justifyContent: 'start' }}>
          <Link to="/imprint">{intl.formatMessage(messages.imprint)}</Link>
        </Col>
      </Row>
    </AntdFooter>
  );
};

export default Footer;
