import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { Route, Routes } from 'react-router-dom';
import Login from '../Login';
import Header from '../../components/Header';
import NotFound from '../../components/NotFound';
import PrivacyPolicy from '../PrivacyPolicy';
import Footer from '../../components/Footer';
import { useIntl } from 'react-intl';
import messages from '../../components/Header/messages';
import Return from '../Return';

function App() {
  const intl = useIntl();
  useEffect(() => {
    document.title = intl.formatMessage(messages.globalHeader);
  }, [intl]);

  return (
    <Layout style={{ display: 'flex', overflow: 'hidden' }}>
      <Header />
      <Content
        style={{
          paddingTop: 64,
          flex: 1,
          overflowY: 'auto',
          height: 'calc(100% - 64px)',
        }}
        role="main"
      >
        <Routes>
          <Route path="/" exact element={<Login />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/return" exact element={<Return />} />
          <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
          <Route path="/imprint" exact element={<PrivacyPolicy imprint />} />
          <Route path="*" exact element={<NotFound />} />
        </Routes>
      </Content>
      <Footer />
    </Layout>
  );
}

export default App;
