import { defineMessages } from 'react-intl';

export default defineMessages({
  loginOrderNumber: {
    id: `loginOrderNumber`,
    defaultMessage: 'Order number',
  },
  loginPostcode: {
    id: `loginPostcode`,
    defaultMessage: 'Order postcode',
  },
  loginSubmitButton: {
    id: `loginSubmitButton`,
    defaultMessage: 'Submit',
  },
  loginRequiredError: {
    id: `loginRequiredError`,
    defaultMessage: 'This field is required',
  },
  loginError: {
    id: `loginError`,
    defaultMessage: 'No valid order found.',
  },
  loginInfo: {
    id: `loginInfo`,
    defaultMessage:
      'In order to be able to provide the information about your return preferences, please fill the required fields',
  },
});
