/*
 *
 * ReturnForm constants
 *
 */

export const STATUS_TYPE_RECEIVED = 'RECEIVED';
export const STATUS_TYPE_PREPARATION = 'PREPARATION';
export const STATUS_TYPE_SHIPPED = 'SHIPPED';
export const STATUS_TYPE_DELIVERED = 'DELIVERED';
export const STATUS_TYPE_CANCELED = 'CANCELED';
export const STATUS_TYPE_NOTDELIVERED = 'NOT_DELIVERED';
export const STATUS_TYPE_FOLLOWUP = 'FOLLOW_UP';
export const STATUS_TYPE_ARRANGED = 'ARRANGED';

export const RETURN_REASON_LATEDELIVERY = 'LATEDELIVERY';
export const RETURN_REASON_DONTLIKEANYMORE = 'DONTLIKEANYMORE';
export const RETURN_REASON_MISSINGPARTSORACCESSORIESMISSING =
  'MISSINGPARTSORACCESSORIESMISSING';
export const RETURN_REASON_ITEMBROKENORDAMAGED = 'ITEMBROKENORDAMAGED';
export const RETURN_REASON_MISPICKWRONGITEMDELIVERED =
  'MISPICKWRONGITEMDELIVERED';
export const RETURN_REASON_FOUNDCHEAPERPRICE = 'FOUNDCHEAPERPRICE';
export const RETURN_REASON_SHIPPINGPACKAGINGDAMAGED =
  'SHIPPINGPACKAGINGDAMAGED';
export const RETURN_REASON_WRONGDESCRIPTION = 'WRONGDESCRIPTION';
export const RETURN_REASON_ITEMTOOBIGORSMALL = 'ITEMTOOBIGORSMALL';
export const RETURN_REASON_NONE = 'NONE';

export const RETURN_STATUS_CREATED = 'CREATED';
export const RETURN_STATUS_PROCESSED = 'PROCESSED';
export const RETURN_STATUS_NOTRETURNED = 'NOTRETURNED';
export const RETURN_STATUS_CREATING = 'CREATING';