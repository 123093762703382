import { defineMessages } from "react-intl";

export default defineMessages({
  notFoundMessage: {
    id: `notFoundMessage`,
    defaultMessage: "Sorry, the page you visited does not exist."
  },
  notFoundBack: {
    id: `notFoundBack`,
    defaultMessage: "Back Home"
  }
});
