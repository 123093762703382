import { defineMessages } from 'react-intl';

export default defineMessages({
  returnGoToLogin: {
    id: `returnGoToLogin`,
    defaultMessage: 'Sorry, you are not authorized to access this page.',
  },
  returnBack: {
    id: `returnBack`,
    defaultMessage: 'Back to login',
  },
});
